import React, {Component} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './content/theory/javaScript/js';

//components
// import { NavigationBar } from './components/NavigationBar';

//Main
import Home from './content/home_tpl';
import Theory from './content/theory/theory_homepage';
import Exercise from './content/exercise/exercise_homepage';


/*Theory*/
//Testing
import Testing from "./content/theory/testing/testing";
import TestingHomePage from "./content/theory/testing/testing_homepage";
import TestingLevels from "./content/theory/testing/testing_levels";
import TestingLevels2 from "./content/theory/testing/testing_levels_2";
//Testing

//SQL
import TeoriaSQLHomePage from './content/theory/SQL/teoriaHomePageSQL';
import ZakladnePrikazySQL from './content/theory/SQL/zakladnePrikazy';
import {NavigationBar} from "./content/theory/navigationBar";
import ZakladnePrikazySQLs2 from "./content/theory/SQL/zakladnePrikazy_s2";
import ZakladnePrikazySQLs3 from "./content/theory/SQL/zakladnePrikazy_s3";
import ZakladnePrikazySQLs4 from "./content/theory/SQL/zakladnePrikazy_s4";
//SQL

//UML
import UmlHomePage from "./content/theory/uml/umlHomePage";
import UmlZaklady from "./content/theory/uml/uml_zaklady";
import UmlDiagramy from "./content/theory/uml/uml_diagramy";
import UmlUseCase from "./content/theory/uml/uml_diagramy_useCase";
import UmlDomenovyDiagram from "./content/theory/uml/uml_diagramy_domenovy_model";
import UmlVztahy1 from "./content/theory/uml/uml_diagramy_vztahy_1";
import UmlVztahy2 from "./content/theory/uml/uml_diagramy_vztahy_2";
//UML

//LOGY
import LogyHomePage from './content/theory/logy/logy_homepage';
import Logy2 from './content/theory/logy/logy2';
import Logy3 from './content/theory/logy/logy3';
//LOGY

//XML
import XMLHomePage from './content/theory/XML/xmlHomePage';
import XMLSyntax from './content/theory/XML/xml_syntax';
//XML

//HTML
import HTMLHomePage from "./content/theory/HTML/htmlHomePage";
import HTMLBasics from "./content/theory/HTML/htmlBasics";
//HTML

//JSON
import JSONHomePage from "./content/theory/json/json_homepage";
import JSON2 from "./content/theory/json/json_2";
//JSON

//Rest
import RestApiHomePage from "./content/theory/restAPI/restAPI_homepage";
import RestHttpHeader from "./content/theory/restAPI/restAPI_http_header";
import RestHttpHeader2 from "./content/theory/restAPI/restAPI_http_header2";
import RestHttpMetody from "./content/theory/restAPI/restAPI_http_metody";
import RestHttpMetody2 from "./content/theory/restAPI/restAPI_http_metody2";
import RestHttpResponse from "./content/theory/restAPI/restAPI_http_response";
//Rest

//SoapUI
import SoapUIHomePage from "./content/theory/SoapUI/soapUI_homepage";
import SoapUIWhatIsIt from "./content/theory/SoapUI/soapUI_what_is_it";
import SoapUICreateProject from "./content/theory/SoapUI/soapUI_create_project";
import SoapUICreateProject2 from "./content/theory/SoapUI/soapUI_create_project_2";
import SoapUICreateProject3 from "./content/theory/SoapUI/soapUI_create_project_3";
import SoapUICreateProject4 from "./content/theory/SoapUI/soapUI_create_project_4";
import SoapUIDictionary from "./content/theory/SoapUI/soapUI_dictionary";
//SoapUI
/*Theory*/

/*Excercise*/
import SQLExerciseAtoC from "./content/exercise/SQL/SQLExerciseAtoC";
import SQLExerciseDtoF from "./content/exercise/SQL/SQLExerciseDtoF";
import SQLExerciseGtoI from "./content/exercise/SQL/SQLExerciseGtoI";

/*Excercise*/


class App extends Component {
    render() {
        return (
            <React.Fragment>
                <NavigationBar/>
                <Router>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/theory" element={<Theory/>}/>
                        <Route path="/excercise" element={<Exercise/>}/>
                        {/*Theory*/}
                        {/*Testing*/}
                        <Route path="/testing_uvod" element={<TestingHomePage/>}/>
                        <Route path="/testing" element={<Testing/>}/>
                        <Route path="/testing_levels" element={<TestingLevels/>}/>
                        <Route path="/testing_levels_2" element={<TestingLevels2/>}/>
                        {/*Testing*/}
                        {/*SQL*/}
                        <Route path="/teorie_sql" element={<TeoriaSQLHomePage/>}/>
                        <Route path="/zakladni_prikazy_sql" element={<ZakladnePrikazySQL/>}/>
                        <Route path="/zakladni_prikazy_sql_s2" element={<ZakladnePrikazySQLs2/>}/>
                        <Route path="/zakladni_prikazy_sql_s3" element={<ZakladnePrikazySQLs3/>}/>
                        <Route path="/zakladni_prikazy_sql_s4" element={<ZakladnePrikazySQLs4/>}/>
                        {/*SQL*/}
                        {/*UML*/}
                        <Route path="/uml" element={<UmlHomePage/>}/>
                        <Route path="/uml_zaklady" element={<UmlZaklady/>}/>
                        <Route path="/uml_diagramy" element={<UmlDiagramy/>}/>
                        <Route path="/uml_usecase" element={<UmlUseCase/>}/>
                        <Route path="/uml_domenovy_model" element={<UmlDomenovyDiagram/>}/>
                        <Route path="/uml_domenovy_model_vztahy_1" element={<UmlVztahy1/>}/>
                        <Route path="/uml_domenovy_model_vztahy_2" element={<UmlVztahy2/>}/>
                        {/*UML*/}

                        {/*LOGY*/}
                        <Route path="/logy" element={<LogyHomePage/>}/>
                        <Route path="/logy-2" element={<Logy2/>}/>
                        <Route path="/logy-3" element={<Logy3/>}/>
                        {/*LOGY*/}
                        {/*XML*/}
                        <Route path="/xml" element={<XMLHomePage/>}/>
                        <Route path="/xml_syntax" element={<XMLSyntax/>}/>
                        {/*XML*/}

                        {/*HTML*/}
                        <Route path="/html" element={<HTMLHomePage/>}/>
                        <Route path="/htmlBasics" element={<HTMLBasics/>}/>
                        {/*HTML*/}

                        {/*JSON*/}
                        <Route path="/json" element={<JSONHomePage/>}/>
                        <Route path="/json-2" element={<JSON2/>}/>
                        {/*JSON*/}

                        {/*RESTAPI*/}
                        <Route path="/rest" element={<RestApiHomePage/>}/>
                        <Route path="/rest/http-header" element={<RestHttpHeader/>}/>
                        <Route path="/rest/http-header-2" element={<RestHttpHeader2/>}/>
                        <Route path="/rest/http-metody" element={<RestHttpMetody/>}/>
                        <Route path="/rest/http-metody-2" element={<RestHttpMetody2/>}/>
                        <Route path="/rest/http-response-code" element={<RestHttpResponse/>}/>
                        {/*RESTAPI*/}

                        {/*SoapUI*/}
                        <Route path="/soapUI" element={<SoapUIHomePage/>}/>
                        <Route path="/soapUI_what_is_it" element={<SoapUIWhatIsIt/>}/>
                        <Route path="/soapUI_create_project" element={<SoapUICreateProject/>}/>
                        <Route path="/soapUI_create_project_2" element={<SoapUICreateProject2/>}/>
                        <Route path="/soapUI_create_project_3" element={<SoapUICreateProject3/>}/>
                        <Route path="/soapUI_create_project_4" element={<SoapUICreateProject4/>}/>
                        <Route path="/soapUI_dictionary" element={<SoapUIDictionary/>}/>
                        {/*SoapUI*/}

                        {/*Theory*/}

                        {/*Excercise*/}
                        <Route path={"/SQLExcercise"} element={<SQLExerciseAtoC/>}/>
                        <Route path={"/SQLExcerciseDtoF"} element={<SQLExerciseDtoF/>}/>
                        <Route path={"/SQLExcerciseGtoI"} element={<SQLExerciseGtoI/>}/>
                        {/*Excercise*/}

                        {/*<Route path="*" component={NotFound}/>*/}
                    </Routes>
                </Router>
            </React.Fragment>
        );
    }
}

export default App;
