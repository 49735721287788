import React from "react";
import {Link} from "react-router-dom";

import htmlStructureIMG from "./img/htmlStructure.png";

let htmlStr = `<!DOCTYPE html>\n
                    <html lang="en">
                    <head>
                        <title>Page Title</title>
                    </head>
                    <body>

                    <h1>My First Heading</h1>
                    <p>My first paragraph.</p>

                    </body>
                    </html>`;
const HTMLHomePage = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <h1 align="center">HTML</h1>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <ul>
                    <li>
                        HTML je zkratka pro Hyper Text Markup Language
                    </li>
                    <li>
                        HTML je standardní značkovací jazyk pro vytváření webových stránek
                    </li>
                    <li>
                        HTML popisuje strukturu webové stránky
                    </li>
                </ul>
            </div>
            <div className="col">
                <ul>
                    <li>
                        HTML se skládá z řady prvků
                    </li>
                    <li>
                        Prvky HTML říkají prohlížeči, jak má obsah zobrazit
                    </li>
                    <li>
                        Prvky HTML označují části obsahu, jako je „toto je nadpis“, „toto je odstavec“, „toto je odkaz“
                        atd.
                    </li>
                </ul>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <code>
                    {htmlStr}
                </code>
            </div>
        </div>
        <div className="row pt-3">
            <div className="col">
                <ul>
                    <li>
                        Deklarace <code>&lt;!DOCTYPE html></code> definuje, že tento dokument je dokument HTML5
                    </li>
                    <li>
                        Prvek <code>&lt;html></code> je kořenový prvek stránky HTML
                    </li>
                    <li>
                        Element <code>&lt;head></code> obsahuje meta informace o stránce HTML
                    </li>
                    <li>
                        Element <code>&lt;title></code> určuje název stránky HTML (který se zobrazuje v záhlaví prohlížeče nebo na
                        kartě stránky)
                    </li>
                </ul>
            </div>
            <div className="col">
                <ul>
                    <li>
                        Element <code>&lt;body></code> definuje tělo dokumentu a je kontejnerem pro veškerý viditelný obsah, jako
                        jsou nadpisy, odstavce, obrázky, hypertextové odkazy, tabulky, seznamy atd.
                    </li>
                    <li>
                        Element <code>&lt;h1></code> definuje velký nadpis
                    </li>
                    <li>
                        Element <code>&lt;p></code> definuje odstavec
                    </li>
                </ul>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>Co je to prvek HTML?</h3>
                <p>Element HTML je definován počáteční značkou, nějakým obsahem a koncovou značkou:</p>
                <p>&lt;tagname> Obsah jde sem... &lt;/tagname></p>
                <p>Element HTML je vše od počáteční značky po koncovou značku:</p>
                <p>
                    <code>
                        &lt;h1>Můj první nadpis&lt;/h1>
                    &lt;p>Můj první odstavec.&lt;/p>
                    </code>
                </p>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>Struktura stránky HTML</h3>
                <img src={htmlStructureIMG} alt=""/>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <Link to="/htmlBasics" className="d-flex justify-content-center">
                    Další strana
                </Link>
            </div>
        </div>
    </div>
)

export default HTMLHomePage;