import React from "react";
import { Link } from "react-router-dom";

const RestHttpHeader2 = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">REST API - HTTP HEADERS</h1>
                </div>
            </div>
        </div>
        <div className="row">
            <h2>Jak vypadá header?</h2>
            <p>Při otevření stránky blue-pool.cz</p>
            <div className="col">
                <p>Request Header</p>
                <div className="code">
                    <code>
                        GET / HTTP/2<br></br>
                        Host: blue-pool.cz<br></br>
                        User-Agent: Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:101.0) Gecko/20100101 Firefox/101.0<br></br>
                        Accept: text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,*/*;q=0.8<br></br>
                        Accept-Language: cs,sk;q=0.8,en-US;q=0.5,en;q=0.3<br></br>
                        Accept-Encoding: gzip, deflate, br<br></br>
                        Connection: keep-alive<br></br>
                        Cookie: _ga=GA1.2.1619382043.1599140582; PHPSESSID=7119769a98dc6c092a7037bf915f5cbe<br></br>
                        Upgrade-Insecure-Requests: 1<br></br>
                        Sec-Fetch-Dest: document<br></br>
                        Sec-Fetch-Mode: navigate<br></br>
                        Sec-Fetch-Site: cross-site<br></br>
                    </code>
                </div>
            </div>
            <div className="col">
                <p>Response Header</p>
                <div className="code">

                    <code>
                        HTTP/2 200 OK<br></br>
                        date: Tue, 07 Jun 2022 06:25:57 GMT<br></br>
                        server: Apache<br></br>
                        expires: Thu, 19 Nov 1981 08:52:00 GMT<br></br>
                        cache-control: no-store, no-cache, must-revalidate, post-check=0, pre-check=0<br></br>
                        pragma: no-cache<br></br>
                        access-control-allow-origin: *<br></br>
                        vary: Accept-Encoding<br></br>
                        content-encoding: gzip<br></br>
                        cache-control: max-age=0, no-cache, no-store, must-revalidate<br></br>
                        strict-transport-security: max-age=63072000; includeSubDomains; preload<br></br>
                        content-length: 6991<br></br>
                        content-type: text/html<br></br>
                        X-Firefox-Spdy: h2<br></br>
                    </code>
                </div>
            </div>
            <p>
                Na headery stránek se můžete podívat otevřením vývojářské konzole, většinou tlačítko <kbd>F12</kbd> a poté záložka síť/network
            </p>
        </div>


        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/rest/http-response-code" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default RestHttpHeader2;