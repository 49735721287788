import React from "react";
import {Link} from "react-router-dom";

const ZakladnePrikazySQLs3 = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <h1 className="d-flex justify-content-center">
                    Základní SQL příkazy
                </h1>
            </div>
        </div>
        <div className="row pt-4">
            <div className="col">
                <h3>
                    JOIN
                </h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>
                    JOIN slouží ke spojení dvou a více tabulek. Spojení je prováděno pomocí primárního klíče v tabulce
                    jedné a cizího klíče v tabulce druhé.
                </p>
                <p>
                    Syntaxe: <code>SELECT jake_sloupečky FROM nazev_tabulky_1 JOIN nazev_tabulky_2 ON
                    nazev_tabulky_1.primarni_klic = nazev_tabulky_2.cizi_klic;</code>
                </p>
                <p>
                    Příklad: <code>SELECT * FROM uzivatel join projekt on uzivatel.ID = projekt.ID_uzivatel;</code>
                </p>
            </div>
        </div>
        <div className="row pt-4">
            <div className="col">
                <h3>
                    Vnořený SELECT
                </h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>
                    Je alternativou pro JOIN tabulek s podmínkou WHERE. Někomu může vyhovovat více než JOIN. Princip je
                    takový, že se dva (a více) dotazů SELECT zanoří do sebe pomocí WHERE podmínky.
                </p>
                <p>
                    Příklad: <code>SELECT * FROM uzivatel WHERE ID in (SELECT ID_uzivatel FROM projekt);</code>
                </p>
                <p>
                    Dotaz vrací stejný výsledek jako výše uvedený JOIN.
                </p>
            </div>
        </div>
        <div className="row pt-4">
            <div className="col">
                <h3>
                    INSERT, UPDATE, DELETE
                </h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>
                    Pomocí příkazu INSERT vložíme záznam do tabulky.
                </p>
                <p>
                    Struktura příkazu:
                    <code>INSERT INTO tabulka (sloupec1, sloupec2, sloupec3, ...) VALUES (‘hodnota1’,
                        ‘hodnota2’, ‘hodnota3’, ...);</code>
                </p>
                <p>
                    Příkaz INSERT vloží do tabulky vždy jeden řádek.
                </p>
            </div>
        </div>
        <div className="row pt-3">
            <div className="col">
                <p>
                    Editace záznamu se provádí pomocí příkazu UPDATE.
                </p>
                <p>
                    Struktura příkazu:
                    <code>UPDATE tabulka SET atribut = ‘hodnota’ WHERE atribut = ‘hodnota’;</code>
                </p>
                <p>
                    U příkazu UPDATE nesmí tester zapomenou na WHERE podmínku, je to častá chyba, která vede k poškození
                    celé tabulky (updatují se všechny záznamy), doporučenou pojistkou je psát na konec příkazu LIMIT 1
                    (povolí update pouze jednoho záznamu). V podmínce WHERE by se měl jako atribut používat primární
                    klíč nebo jiný unikátní identifikátor updatovaného záznamu.
                </p>
                <p>
                    Příklad: <code>UPDATE osoba SET jmeno = ‘Petr’ WHERE ID = ‘5’ LIMIT 1;</code>
                </p>
            </div>
        </div>
        <div className="row pt-3">
            <div className="col">
                <p>
                    Smazání záznamu se provádí pomocí příkazu DELETE.
                </p>
                <p>
                    Struktura příkazu:
                    <code>DELETE FROM tabulka WHERE atribut = ‘hodnota’ LIMIT 1;</code>
                </p>
                <p>
                    Vždy uvádět podmínku a pokud chceme mazat jen jeden záznam, tak uvést i LIMIT, stejný důvod jako u
                    UPDATE (ale následky jsou horší, mazání deletem je v relační databázi obtížně vratná operace).
                </p>
                <p>
                    <code>Příklad: UPDATE osoba SET jmeno = ‘Petr’ WHERE ID = ‘5’ LIMIT 1;</code>
                </p>
            </div>
        </div>
        <div className="row pt-4">
            <div className="col">
                <Link to="/zakladni_prikazy_sql_s4" className="d-flex justify-content-center">
                    Další strana
                </Link>
            </div>
        </div>
    </div>
)

export default ZakladnePrikazySQLs3;