import React from "react";
import {Link} from "react-router-dom";

import umlDiagramy from "./img/UML_tree.png";

const UmlDiagramy = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1>Teorie UML - diagramy</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <div className="d-flex">
                    <p>UML se v současné době skládá ze 14ti diagramů</p>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <img src={umlDiagramy} className="w-60" alt=""/>
                </div>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/uml_usecase" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default UmlDiagramy;