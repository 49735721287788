import React from "react";
import {Link} from "react-router-dom";

const UmlHomePage = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">Teorie UML</h1>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p className="justify-center">UML (Unified Modeling Language) je soubor grafických notací, který se
                    používá
                    pří vývoji
                    softwaru. V oblasti analýzy a návrhu se stal standardem. UML je použito v mnoha materiálech, v
                    dokumentacích a podobně. Hlavně ale může sloužit jako užitečný nástroj k usnadnění
                    návrhu a vývoje informačního systému. Je jedním ze standardů pomocí nichž se modelují
                    business procesy.</p>
            </div>
        </div>
        <div className="row pt-4">
            <div className="col">
                <h3>Význam UML</h3>
                <p className="text-justify">Systém je nutné nejprve navrhnout, již si nemůžeme jen tak sednout a psát
                    nějaký kód.
                    Při jeho implementaci je nutné pracovat v týmu programátorů, jelikož jeden nato určitě nestačí. V
                    týmu musí probíhat velmi dobrá komunikace. Dále je nutné reagovat na změny v zadání klienta.
                    Velmi těžké může být odhadnout cenu systému v počátečních fázích vývoje. UML je jeden z nástrojů,
                    který
                    nám pomáhá se s těmito problémy
                    vypořádat. Pomůže ve fázi
                    analýzy, kdy se komunikuje s klientem a zjišťuje se, co se bude vlastně programovat. A pomůže
                    i ve fázi designu, kdy se řeší otázka jak to naprogramovat.</p>
                <br/>
                <h3>UML lze oběcne použít třema způsoby:</h3>
                <ul>
                    <li>- UML jako náčrt (sketch)</li>
                    <li>- UML jako plán (blueprint)</li>
                    <li>- UML jako programovací jazyk</li>
                </ul>
            </div>
        </div>
        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/uml_zaklady" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default UmlHomePage;