import React from "react";
import {Link} from "react-router-dom";
import useCaseIMG from "./img/useCase.png";

const UmlUseCase = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">Teorie UML - Use Case</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>Use Case Diagram (českydiagram případůužití) zobrazujechovánísystémutak, jakho vidíuživatel.
                    Účelemdiagramuje popsatfunkcionalitusystému,tedyco od nějklientnebomy očekáváme. Diagram vypovídáo
                    tom, co másystémumět, ale neříkájakto budedělat. Proto je to většinouprvnídiagram,
                    kterýpřinávrhuinformačníhosystémuvytváříme.</p>
                <p>Use Case diagram se skládáz případůužití(use case), dáleaktérů(actors) a vztahůmezinimi.</p>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>Případužití(nebozkráceněUC) je sadaněkolikaakcí, kterévedouk dosaženíurčitéhocíle. Use Case
                    můžebýtpřidáníkomentářek článku, založenínovéhouživatelenebonapř. vytisknutídokumentu.
                    Definujetedyjednufunkcionalitu, kterouby mělnavrhovanýsystémumět.</p>
                <p>Může v sobě obsahovat další jako například ověření uživatele, validaci zadaných dat, zápis do
                    databáze a podobně.</p>
                <br/>
                <p>Use Case se zakresluje jako elipsa s jeho názvem uvnitř.</p>
                <div className="d-flex justify-content-center">
                    <img src={useCaseIMG} alt="UseCaseIMG"/>
                </div>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/uml_domenovy_model" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default UmlUseCase;