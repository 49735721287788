import React from 'react'
import { Link } from 'react-router-dom';

//Styles
import '../../App.css';

const Theory = () => (
    <div className="row">
        <div className="col">
            <div className="container-fluid">
                <div className="center-window">
                    <div className="col">
                        <div className="flex-container justify-content-center">
                            <Link to="/testing" className="card">
                                <div className="card-body align-items-center d-flex justify-content-center">
                                    <p>Co je testování?</p>
                                </div>
                            </Link>
                            <Link to="/teorie_sql" className="card">
                                <div className="card-body align-items-center d-flex justify-content-center">
                                    <p>SQL</p>
                                </div>
                            </Link>
                            <Link to="/uml" className="card">
                                <div className="card-body align-items-center d-flex justify-content-center">
                                    <p>UML</p>
                                </div>
                            </Link>
                            <Link to="/logy" className="card">
                                <div className="card-body align-items-center d-flex justify-content-center">
                                    <p>LOGY</p>
                                </div>
                            </Link>
                        </div>
                        <div className="flex-container justify-content-center">
                            <Link to="/xml" className="card">
                                <div className="card-body align-items-center d-flex justify-content-center">
                                    <p>XML</p>
                                </div>
                            </Link>
                            <Link to="/html" className="card">
                                <div className="card-body align-items-center d-flex justify-content-center">
                                    <p>HTML</p>
                                </div>
                            </Link>
                            <Link to="/json" className="card">
                                <div className="card-body align-items-center d-flex justify-content-center">
                                    <p>JSON</p>
                                </div>
                            </Link>
                            <Link to="/rest" className="card">
                                <div className="card-body align-items-center d-flex justify-content-center">
                                    <p>REST API</p>
                                </div>
                            </Link>
                        </div>
                        <div className="flex-container justify-content-center">
                            <Link to="/soapUI" className="card">
                                <div className="card-body align-items-center d-flex justify-content-center">
                                    <p>SoapUI</p>
                                </div>
                            </Link>
                            {/* <Link to="#" className="card">
                                <div className="card-body align-items-center d-flex justify-content-center">
                                    <p>Postman</p>
                                </div>
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Theory;