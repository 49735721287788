import React from "react";
import {Link} from "react-router-dom";

const UmlZaklady = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1>Teorie UML</h1>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <h3>UML jako náčrt (sketch)</h3>
                <p className="text-justify">UML diagramy můžeme používat ve velmi jednoduché podobě jako náčrt.
                    Obvykle se jedná o
                    ručně kreslené diagramy na tabuli nebo do sešitu. Používají je při jednání s klientem, kde
                    grafická podoba problému pomůže ho lépe pochopit a usnadní komunikaci.</p>
                <p className="text-justify">Diagramy mají velmi důležitou vlastnost a tou je abstrakce. Každý
                    diagram je vlastně určitý
                    pohled na systém, pohled z určitého úhlu. Zbytek systému se zanedbává a zobrazí se jen to,
                    co je v danou chvíli důležité. UML diagramy zlepšují komunikaci a snižují riziko, že
                    něčemu špatně porozumí zhotovitel nebo klient a systém bude špatně navržený.</p>
                <h3 className="pt-2">UML jako plán (blueprint)</h3>
                <p className="text-justify">UML jako plán je o mnoho detailnější než náčrt. Diagramy jsou
                    vytvářeny v CAD nástrojích a
                    slouží jako plán implementace pro programátory. Usnadňují komunikaci v týmu a ulehčují
                    implemetaci systému, jelikož se díky diagramům programátoři v systému lépe orientují. Po
                    dokončení systému slouží diagramy dále jako dokumentace. Jelikož je UML standardem, i
                    nezasvěcený programátor se bude schopen v systému orientovat.</p>
                <h3 className="pt-2">UML jako programovací jazyk</h3>
                <p>Třetím významem je UML jako programovací jazyk. Z detailního UML diagramu lze vygenerovat
                    šablonu kódu, která slouží jako základ pro implementaci. V databázích se běžně tyto
                    modely používají pro vygenerování zakládacích skriptů.</p>
            </div>
        </div>
        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/uml_diagramy" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default UmlZaklady;