import React from "react";
import { Link } from "react-router-dom";

const JSONHomePage = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">JSON</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>JavaScript Object Notation (JavaScriptový objektový zápis, JSON) je způsob zápisu dat (datový formát) nezávislý na počítačové platformě, určený pro přenos dat, která mohou být organizována v polích nebo agregována v objektech. Vstupem je libovolná datová struktura (číslo, řetězec, boolean, objekt nebo z nich složené pole), výstupem je vždy řetězec. Složitost hierarchie vstupní proměnné není teoreticky nijak omezena. </p>
                <p>
                    JSON je založen na dvou strukturách:
                    <ul>
                        <li>
                            Kolekce párů název/hodnota. Ta bývá v rozličných jazycích realizována jako objekt, záznam (record), struktura (struct), slovník (dictionary), hash tabulka, klíčový seznam (keyed list) nebo asociativní pole.
                        </li>
                        <li>
                            Seřazený seznam hodnot. Ten je ve většině jazyků realizován jako pole, vektor, seznam (list) nebo posloupnost (sequence).
                        </li>
                    </ul>
                    Jedná se o univerzální datové struktury a v podstatě všechny moderní programovací jazyky je v nějaké formě podporují. Je tedy logické, aby na nich byl založen i na jazyce nezávislý výměnný formát.
                </p>
                <p>
                    V JSON jsou tyto struktury realizovány s využitím následujících konstrukcí:<br></br>
                    Objekt je neuspořádaná množina párů název/hodnota. Objekt je uvozen znakem <code>&#123;</code> a zakončen znakem <code>&#125;</code>. Každý název je následován znakem <code>:</code> a páry název/hodnota jsou pak odděleny znakem <code>,</code>
                </p>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/json-2" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default JSONHomePage;