import React from "react";
import {Link} from "react-router-dom";

const XMLHomePage = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">XML</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>Co je XML?</h3>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <ul>
                    <li>XML je zkratka pro eXtensible Markup Language</li>
                    <li>XML je značkovací jazyk podobný HTML</li>
                    <li>XML bylo navrženo pro ukládání a přenos dat</li>
                </ul>
            </div>
            <div className="col">
                <ul>
                    <li>XML bylo navrženo tak, aby bylo samopopisné</li>
                    <li>XML je doporučení W3C</li>
                </ul>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>XML nic nedělá</h3>
                <p>Možná je to trochu těžké pochopit, ale XML nic nedělá.</p>
                <code>{'<note>' +
                    '<to>Tove</to>' +
                    '<from>Jani</from>' +
                    '<heading>Reminder</heading>' +
                    '<body>Don\'t forget me this weekend!</body>' +
                    '</note>'}
                </code>
                <h3>Výše uvedené XML je zcela samopopisné:</h3>
                <ul>
                    <li>Obsahuje informace o odesílateli</li>
                    <li>Obsahuje informace o přijímateli</li>
                    <li>Má nadpis</li>
                </ul>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>Rozdíl mezi XML a HTML</h3>
                <p>XML a HTML byly navrženy s různými cíli:</p>
                <ul>
                    <li>XML bylo navrženo tak, aby přenášelo data – se zaměřením na to, co data jsou</li>
                    <li>HTML bylo navrženo tak, aby zobrazovalo data – se zaměřením na to, jak data vypadají</li>
                    <li>XML tagy nejsou předdefinované jako HTML tagy</li>
                </ul>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>XML zjednodušuje věci</h3>
                <ul>
                    <li>XML zjednodušuje sdílení dat</li>
                    <li>XML zjednodušuje přenos dat</li>
                    <li>XML zjednodušuje změny platformy</li>
                    <li>XML zjednodušuje dostupnost dat</li>
                </ul>
                <p>
                    Mnoho počítačových systémů obsahuje data v nekompatibilních formátech. Výměna dat mezi
                    nekompatibilními systémy (nebo upgradovanými systémy) je pro vývojáře webu časově náročný úkol. Je
                    třeba převést velké množství dat a často dochází ke ztrátě nekompatibilních dat.

                    XML ukládá data ve formátu prostého textu. To poskytuje softwaru a hardwaru nezávislý způsob
                    ukládání, přenášení a sdílení dat.

                    XML také usnadňuje rozšíření nebo upgrade na nové operační systémy, nové aplikace nebo nové
                    prohlížeče, aniž by došlo ke ztrátě dat.

                    S XML mohou být data dostupná všem druhům „čtecích strojů“, jako jsou lidé, počítače, hlasové
                    stroje, zpravodajské kanály atd.</p>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/xml_syntax" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default XMLHomePage;