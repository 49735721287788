import React from "react";
import {Link} from "react-router-dom";

const ZakladnePrikazySQL = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <h1 className="d-flex justify-content-center">Základní SQL příkazy</h1>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <h3>SELECT</h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <div className="">
                    <p>Základní struktura dotazu je: SELECT <span
                        className="sqlTeorieSpan">jake_sloupečky</span> FROM <span
                        className="sqlTeorieSpan">nazev_tabulky;</span></p>

                    Příklad: SELECT <span className="sqlTeorieSpan">jmeno, prijmeni</span> FROM <span
                    className="sqlTeorieSpan">uzivatel</span>;
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>Pokud chceme vypsat <strong>všechny sloupečky</strong>, použijeme * (např. SELECT * FROM uzivatel;)
                </p>
                <p>Jednotlivé dotazy ukončujeme pomocí <span className="sqlTeorieSpan">;</span></p>
                <p>Pokud chceme z tabulky vybrat <strong>unikátní hodnoty</strong>, použijeme funkci distinct (např.
                    SELECT
                    distinct(jmeno) FROM uzivatel;)</p>
            </div>
        </div>

        <div className="row pt-4">
            <div className="col">
                <h3>PODMÍNKY</h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>SQL dotaz můžeme upřesnit podmínkou, nejčastěji se jedná o podmínku WHERE. Podmínka se v dotazu
                    umísťuje za název tabulky.</p>
                <p>Syntaxe: SELECT <span className="sqlTeorieSpan">jake_sloupečky</span> FROM <span
                    className="sqlTeorieSpan">nazev_tabulky</span> WHERE <span className="sqlTeorieSpan">sloupeček = “hodnota”;</span>
                </p>
                <p>Příklad: SELECT <span className="sqlTeorieSpan">*</span> FROM <span
                    className="sqlTeorieSpan">uzivatel</span> WHERE <span
                    className="sqlTeorieSpan">jmeno = “Pepík”;</span></p>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p className="py-2">Možné operátory v podmínce:</p>
                <table>
                    <thead>
                    <th>Operátor</th>
                    <th>Význam</th>
                    </thead>
                    <tbody>
                    <tr>
                        <td>=</td>
                        <td>rovná se</td>
                    </tr>
                    <tr>
                        <td>&lt; &gt;, !=</td>
                        <td>nerovná se</td>
                    </tr>
                    <tr>
                        <td>like</td>
                        <td>odpovídá (používá se pro částečnou shodu řetězců, viz níže)</td>
                    </tr>
                    <tr>
                        <td>not like</td>
                        <td>neodpovídá</td>
                    </tr>
                    <tr>
                        <td>&lt;</td>
                        <td>větší než</td>
                    </tr>
                    <tr>
                        <td>&gt;</td>
                        <td>menší než</td>
                    </tr>
                    <tr>
                        <td>in (x, y, z, ...)</td>
                        <td>je součástí výčtové množiny</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="row pt-4">
            <div className="col">
                <Link to="/zakladni_prikazy_sql_s2" className="d-flex justify-content-center">Další strana</Link>
            </div>
        </div>
    </div>
)

export default ZakladnePrikazySQL;
