import React from 'react';
import {Navbar} from 'react-bootstrap';
import '../../App.css';

import logo from "../img/BluePool_Logo.png"

export const NavigationBar = () => (
    <Navbar expand="lg">
        <div className="navContainer">
            <div className="row flex-row">
                <div className="col">
                    <Navbar.Brand href="/"><img src={logo} alt="Logo" className="navLogo navbar-brand"/></Navbar.Brand>
                </div>
                <div className="col d-flex">
                    <div className="d-flex align-items-center">Prihlásený užívateľ </div>
                </div>

            </div>
        </div>
    </Navbar>
)