import React from "react";
import { Link } from "react-router-dom";

const Logy3 = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <h1 align="center">JSON</h1>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <h4>Příklad access logu</h4>

                <p>"-" znamená, že informace není dostupná</p>
                
                <p><code>IP - - "datum" "metoda adresa" "navratový kód" "velikost návratového objektu bez hlaviček" "informace o klientovi"</code></p>
                <code>
                195.144.106.250 - - [08/Jun/2022:09:12:24 +0200] "GET /admreg/template_content_ajax/assets/plugins/jquery-ui/ui/minified/jquery-ui.min.js HTTP/1.1" 200 61419 "https://registrace-test.udhpsh.cz/admreg/login.php?action=logout&note=auto" "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Safari/537.36 OPR/86.0.4363.59"<br/>
                195.144.106.250 - - [08/Jun/2022:09:12:24 +0200] "GET /admreg/template_content_ajax/assets/img/login-bg/bg-6.jpg HTTP/1.1" 200 210135 "https://registrace-test.udhpsh.cz/admreg/login.php?action=logout&note=auto" "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Safari/537.36 OPR/86.0.4363.59"<br/>
                195.144.106.250 - - [08/Jun/2022:09:12:24 +0200] "GET /favicon.ico HTTP/1.1" 304 186 "https://registrace-test.udhpsh.cz/admreg/login.php?action=logout&note=auto" "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Safari/537.36 OPR/86.0.4363.59"<br/>
                195.144.106.250 - - [08/Jun/2022:09:12:25 +0200] "POST /admreg/login.php?akce=login HTTP/1.1" 302 424 "https://registrace-test.udhpsh.cz/admreg/login.php?action=logout&note=auto" "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Safari/537.36 OPR/86.0.4363.59"<br/>
                195.144.106.250 - - [08/Jun/2022:09:12:25 +0200] "GET /admreg/index.php?login=ok HTTP/1.1" 200 5227 "https://registrace-test.udhpsh.cz/admreg/login.php?action=logout&note=auto" "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Safari/537.36 OPR/86.0.4363.59"<br/>
                195.144.106.250 - - [08/Jun/2022:09:12:25 +0200] "GET /admreg/template_content_ajax/assets/plugins/bootstrap-datepicker/css/bootstrap-datepicker.css HTTP/1.1" 200 2549 "https://registrace-test.udhpsh.cz/admreg/index.php?login=ok" "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Safari/537.36 OPR/86.0.4363.59"<br/>
                195.144.106.250 - - [08/Jun/2022:09:12:25 +0200] "GET /admreg/template_content_ajax/assets/plugins/gritter/css/jquery.gritter.css HTTP/1.1" 200 1035 "https://registrace-test.udhpsh.cz/admreg/index.php?login=ok" "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Safari/537.36 OPR/86.0.4363.59"<br/>
                </code>
            </div>

        </div>


        <div className="row">
            <div className="col">
                <Link to="/" className="d-flex justify-content-center">
                    Domů
                </Link>
            </div>
        </div>
    </div>

)

export default Logy3