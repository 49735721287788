import React from 'react'
import {Link} from 'react-router-dom';
import {BasicTextArea, BasicButton} from "../../components/reusedComponents";

const SQLExerciseAtoC = () => (
    <div className="container pt-page">
        <div className="row mb-4">
            <div className="col">
                <h1 align="center">SQL Cvičení</h1>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col">
                <p>A) Vypiš všechny zaměstnance (jejich ID), kteří měli někdy dovolenou. Typ záznamu v tabulce dovolená
                    řešit nemusíš</p>
                <form id={"questionA"} action="./api/cviceni_sql.php" method='POST'>
                    <BasicTextArea name="answer" id="1" form={"questionA"} cols="1" rows="1" className={"w-100"} required={true}/>
                    <BasicButton type={"submit"} id={"id"} value={"buttonID"} name={"sendButton"} className={"className"}/>
                </form>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col">
                <p>B) Vypiš počet osob na pozici Tester</p>
                <form id={"questionB"} action="">
                    <BasicTextArea name="answer" id="1" form={"questionB"} cols="1" rows="1" className={"w-100"} required={true}/>
                    <BasicButton type={"submit"} id={"id"} value={"buttonID"} name={"sendButton"} className={"className"}/>
                </form>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col">
                <p>C) Mzda všech zaměstnanců na HPP</p>
                <form id={"questionC"} action="">
                    <BasicTextArea name="answer" id="1" form={"questionC"} cols="1" rows="1" className={"w-100"} required={true}/>
                    <BasicButton type={"submit"} id={"id"} value={"buttonID"} name={"sendButton"} className={"className"} placeholder={"OKOKO"}/>
                </form>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <Link to="/SQLExcerciseDtoF" className="d-flex justify-content-center">
                    Další strana
                </Link>
            </div>
        </div>
    </div>
)

export default SQLExerciseAtoC;