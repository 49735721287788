import React from "react";
import {Link} from "react-router-dom";

//Images
import zakladna_obrazovka from "./img/existujuce_projekt.png"

const SoapUI_homepage = () => (
    <div className="container pt-page soapUI">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">SoapUI</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>Testování Webových služeb (Web Service)</h3>
                <p>Testování webových služeb (Web Service) je většinou součástí Integračních testů. Je nutné ověřit, že
                    příslušná webová služba na dotaz vrací odpovídající odpověď.</p>
                <p>Zaměřuje se na funkčnost, která není primárně přístupná prostřednictvím GUI (Grafické uživatelské
                    rozhraní). V rámci integračních testů je vhodné ji testovat právě takto napřímo. Tedy přímým
                    oslovováním konkrétní webové služby.</p>
                <p>Nejlepší cestou je tedy testovat správnou funkci webových služeb na bázi přímé komunikace s nimi. A
                    právě k tomu lze využít nástroj SOAP UI. </p>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>SoapUI</h3>
                <p>SoapUI je možné při testování používat několika způsoby. Nejzákladnější je přímá editace XML souborů
                    a jejich zasílání na příslušnou webovou službu s tím, že je zobrazena získaná odpověď.</p>
                <p>SoapUI ale umožňuje komplexnější přístup k testování.</p>
                <p>Tester zde může vytvářet ucelenou testovací dokumentaci. Pracuje se zde s pojmy jako je Test Suite
                    (tedy množina testovacích případů), Test Case (klasický testovací případ) a Test Step (testovací
                    krok).</p>
                <h3>Základní obrazovka nástroje SoapUI</h3>
                <div className="row">
                    <div className="col text-center">
                        <img src={zakladna_obrazovka} alt={"zakladni_obrazovka"}/>
                    </div>
                </div>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/SoapUI_what_is_it" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default SoapUI_homepage;