import React from "react";
import {Link} from "react-router-dom";
import UML_Association from "./img/UML_Association.png";
import UML_Aggregation from "./img/UML_Aggregation.png";
import UML_Composition from "./img/UML_Composition.png";

const UmlVztahy1 = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">Teorie UML - Vztahy</h1>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <h3>Asociace (Association)</h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>Asociace určuje základní vztah mezi dvěma entitami. Ty mohou existovat nezávisle na sobě. Zakreslují
                    se jako jednoduchá plná čára.Příklad jednoduché asociace mezi 2mi entitami může být Auto a Řidič.
                    Vztah by se
                    znázornil takto:</p>
                <div className="d-flex justify-content-center">
                    <img src={UML_Association} alt=""/>
                </div>
            </div>
        </div>
        <div className="row pt-3">
            <div className="col">
                <h3>Agregace (Aggregation)</h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>Agregace reprezentuje vztah typu celek-část. Znázorňuje se jako jednoduchá plná čára,
                    zakončená na jedné straně prázdným kosočtvercem. Ten je umístěn u té entity, která reprezentuje
                    celek (např.
                    sekces články).</p>
                <p>Příkladem agregace může být již zmíněná sekce, obsahující články. Čísla na konci vazby znamenají tzv.
                    multiplicitu (viz dále), přesněji, že sekce obsahuje libovolný počet článků a článek patří alespoň
                    do 1
                    sekce.</p>
                <div className="d-flex justify-content-center">
                    <img src={UML_Aggregation} alt="" className="w-30"/>
                </div>
            </div>
        </div>
        <div className="row pt-3">
            <div className="col">
                <h3>Kompozice (Composition)</h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>Kompozice je podobná agregaci, avšak reprezentuje silnější vztah. Entita části nemá bez celku smysl.
                    Pokud zanikne celek, zanikají automaticky i jeho části.Kompozici se zakresluje stejně jako agregaci,
                    kosočtverec je ovšem plný. U entity
                    reprezentující celek musí být multiplicita vždy 1.Příkladem může být Objednávka a Položka objednávky.
                    Zatímco článek z minulého příkladu dává bez sekce ještě nějaký smysl, „Položka objednávky“ bez
                    „Objednávky“ smysl nedává. Proto je zde použita kompozice.</p>
                <div className="d-flex justify-content-center">
                    <img src={UML_Composition} alt="" className="w-30"/>
                </div>
            </div>
        </div>
        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/uml_domenovy_model_vztahy_2" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default UmlVztahy1;