import React from "react";
import {Link} from "react-router-dom";

//Images
import example_wsdl from "./img/exaple_wsdl.png"
import example_wsdl_xml from "./img/example_wsdl_xml.png"
import request_values from "./img/reuqest_values.png"
import request_values_inserted from "./img/request_value_inserted.png"

const SoapUICreateProject3 = () => (
    <div className="container pt-page soapUI">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">Vytvoření nového SOAP projektu</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>hodnoty se doplňují dle specifikace nebo příkladu</p>
                <img src={example_wsdl} alt="example_wsdl"/>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>v případě ARES je to příklad wwwinfo.mfcr.cz/ares/xml_doc/schemas/index.html</p>
                <img src={example_wsdl_xml} alt="example_wsdl_xml"/>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>vlož hodnoty z příkladu do ‚Requestu‘</p>
                <img src={request_values} alt=""/>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <img src={request_values_inserted} alt=""/>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/SoapUI_create_project_4" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default SoapUICreateProject3;