import React from "react";
import {Link} from "react-router-dom";

const HTMLBasics = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <h1 align="center">HTML</h1>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>Selektor ID</h3>
                <p>Atribut HTML id se používá k určení jedinečného ID prvku HTML. V dokumentu HTML nemůžete mít více než
                    jeden prvek se stejným ID.</p>
                <p>Atribut id určuje jedinečné ID prvku HTML. Hodnota atributu id musí být v dokumentu HTML jedinečná.

                    Atribut id se používá k ukázání na konkrétní deklaraci stylu v šabloně stylů. Je také používán
                    JavaScriptem pro přístup a manipulaci s prvkem s konkrétním ID.

                    Syntaxe pro id je: napište znak hash (#) následovaný jménem id.</p>
                <p>Příklad: <code>&lt;h1 id="myHeader">My Cities &lt;h1></code></p>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>Selektor třída (class)</h3>
                <p>Atribut třídy HTML se používá k určení třídy pro prvek HTML. Více prvků HTML může sdílet stejnou
                    třídu.</p>
                <p>Atribut class se často používá k označení názvu třídy v šabloně stylů. Může být také použit
                    JavaScriptem pro přístup a manipulaci s prvky s konkrétním názvem třídy. V následujícím příkladu
                    máme tři prvky &lt;div> s atributem class s hodnotou "city".</p>
                <p>Příklad: <code>&lt;div class="city"></code></p>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <Link to="/" className="d-flex justify-content-center">
                    Domů
                </Link>
            </div>
        </div>
    </div>
)

export default HTMLBasics;