import React from "react";
import {Link} from "react-router-dom";

const SoapUIDictionary = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">SOAP - slovník pojmů</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>
                    SoapUI je nástroj pro testování. SOAP je protokol pro zasílání zpráv mezi webovými službami. SOAP
                    slouží jako obálka pro zprávy
                    webových služeb v XML. SOAP obálka se skládá ze dvou částí, hlavičky a těla. Hlavička je volitelná
                    část SOAP obálky a může nést informace jako je autentizace nebo kódování. Tělo SOAP obálky je
                    povinné a obsahuje vlastní XML zprávu definovanou pomocí WSDL.
                </p>
                <p>WSDL (Web Services Description Language) je XML dokument, který slouží k popisu rozhraní webové
                    služby. V rámci této specifikace by mělo být popsáno rozhraní a další informace o dané webové
                    službě.</p>
                <p>XML je rozšiřitelný značkovací jazyk, který je kompatibilní s řadou programovacích jazyků a nástrojů.
                    Díky tomu se hodí převážně pro výměnu dat mezi různými aplikacemi. Dokument XML popisuje strukturu
                    předávaných dat. Data jsou označena v souboru jednotlivými tagy.</p>
                <p>Hypertext Transfer Protocol - protokol pro přenos hypertextu, webových stránek. Je základním kamenem
                    pro datovou komunikaci na webu.</p>
                <p>Integrační testování je testování vzájemné integrace 2 a více modulů (případně celých aplikací),
                    které spolu souvisí: Příklad: Jedna aplikace používá určité funkcionality a prvky z aplikace druhé:
                    Cílem je objevit chyby vzniklé spojením a následnou integrací více modulů či systémů a jejich
                    rozhraní.</p>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/" className="d-flex justify-content-center">Domů</Link>
                </div>
            </div>
        </div>
    </div>
)

export default SoapUIDictionary;