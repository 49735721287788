import React from "react";
import { Link } from "react-router-dom";

const RestHttpResponse = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">REST API - HTTP RESPONSE CODE</h1>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>
                    U response headeru sis mohl všimnout čísla 200 na prvním řádku. Toto číslo je stavový kód HTTP (response code).
                </p>

                <p>
                    Stavový kód HTTP je součást hlavičky odpovědi serveru na klientský požadavek. Stavový kód upřesňuje, jak byla odpověď serverem zpracována - jestli byl požadavek vyřízen kladně, záporně, nebo došlo k chybě. Dále je na klientovi, aby stavový kód odpovědi interpretoval a následně podnikl patřičné kroky.
                    Součástí hlavičky odpovědi spolu se stavovým kódem je také stavové hlášení, což je anglický slovní popis stavového kódu. Jsou rozděleny podle charakteru odpovědi do pěti kategorií - informační, úspěch, přesměrování, chybný požadavek (chyba klienta) a chyba serveru. 
                </p>

                <ul>
                    <li>Informační: 100 - 199 (moc se nepoužívají)</li>
                    <li>Úspěch: 200 - 299</li>
                    <li>Přesměrování: 300 - 399</li>
                    <li>Chyba klienta: 400 - 499</li>
                    <li>Chyba serveru: 500 - 599</li>
                </ul>
                <p>
                    Asi nejznámější z těchto stavových kódů je <code>404 - not found</code>, se kterým jste se zcela jistě setkali.
                </p>
            </div>
        </div>
        <p>Stavových kódů je velké množství a jsou zde uvedené jen některé příklady. Pro celý výčet klikněte <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Status" target="_blank" rel="noreferrer">zde</a></p>

        

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/rest/http-metody" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default RestHttpResponse;