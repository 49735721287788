import React from "react";
import {Link} from "react-router-dom";

const TestingLevels2 = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">Úrovně a základní typy testů</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">

            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/testing_levels_2" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default TestingLevels2;