import React from "react";

export const BasicButton = ({
                                type,
                                id,
                                value,
                                name,
                                className,
                                ...props
                            }) => {
    return (
        <div>
            <button type={type} id={id} value={value} name={name} className={className} {...props}>Odeslat</button>
        </div>
    );
}

export const BasicTextArea = ({
                                  name,
                                  id,
                                  className,
                                  form,
                                  cols,
                                  rows,
                                  required,
                                  ...props
                              }) => {
    return (
        <div>
            <textarea name={name} id={id} className={className} form={form} cols={cols} rows={rows}
                      required={required} {...props}></textarea>
        </div>
    )
}