import React from "react";
import { Link } from "react-router-dom";

const Logy2 = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <h1 align="center">JSON</h1>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <h4>Příklad error logu</h4>
                <code>
                [Wed Jun 08 10:25:34.495912 2022] [fcgid:warn] [pid 25605] [client 195.144.106.250:62065] mod_fcgid: stderr: PHP Notice:  Undefined variable: hodnoty in /var/www/clients/client1/web6/web/admreg/registrace.php on line 202, referer: https://registrace-test.udhpsh.cz/admreg/registrace.php?akce=nova_registrace&formular_id=kk<br/>
                [Wed Jun 08 10:25:34.495890 2022] [fcgid:warn] [pid 25605] [client 195.144.106.250:62065] mod_fcgid: stderr: PHP Notice:  Undefined variable: aktiv_log in /var/www/clients/client1/web6/web/admreg/menu.php on line 73, referer: https://registrace-test.udhpsh.cz/admreg/registrace.php?akce=nova_registrace&formular_id=kk<br/>
                [Wed Jun 08 10:25:34.495901 2022] [fcgid:warn] [pid 25605] [client 195.144.106.250:62065] mod_fcgid: stderr: PHP Notice:  Undefined variable: pole in /var/www/clients/client1/web6/web/admreg/registrace.php on line 201, referer: https://registrace-test.udhpsh.cz/admreg/registrace.php?akce=nova_registrace&formular_id=kk<br/>
                [Wed Jun 08 10:25:34.495912 2022] [fcgid:warn] [pid 25605] [client 195.144.106.250:62065] mod_fcgid: stderr: PHP Notice:  Undefined variable: hodnoty in /var/www/clients/client1/web6/web/admreg/registrace.php on line 202, referer: https://registrace-test.udhpsh.cz/admreg/registrace.php?akce=nova_registrace&formular_id=kk<br/>
                [Wed Jun 08 10:25:34.495923 2022] [fcgid:warn] [pid 25605] [client 195.144.106.250:62065] mod_fcgid: stderr: PHP Notice:  Undefined variable: otazniky in /var/www/clients/client1/web6/web/admreg/registrace.php on line 203, referer: https://registrace-test.udhpsh.cz/admreg/registrace.php?akce=nova_registrace&formular_id=kk<br/>
                [Wed Jun 08 10:25:34.495934 2022] [fcgid:warn] [pid 25605] [client 195.144.106.250:62065] mod_fcgid: stderr: PHP Notice:  Undefined variable: kraje_vals in /var/www/clients/client1/web6/web/admreg/registrace.php on line 191, referer: https://registrace-test.udhpsh.cz/admreg/registrace.php?akce=nova_registrace&formular_id=kk<br/>
                [Wed Jun 08 10:25:34.495946 2022] [fcgid:warn] [pid 25605] [client 195.144.106.250:62065] mod_fcgid: stderr: PHP Fatal error:  Uncaught PDOException: SQLSTATE[42000]: Syntax error or access violation: 1110 Column 'datum_zverejneni' specified twice in /var/www/clients/client1/web6/web/admreg/registrace.php:214, referer: https://registrace-test.udhpsh.cz/admreg/registrace.php?akce=nova_registrace&formular_id=kk<br/>
                [Wed Jun 08 10:25:34.495957 2022] [fcgid:warn] [pid 25605] [client 195.144.106.250:62065] mod_fcgid: stderr: Stack trace:, referer: https://registrace-test.udhpsh.cz/admreg/registrace.php?akce=nova_registrace&formular_id=kk<br/>
                [Wed Jun 08 10:25:34.496021 2022] [fcgid:warn] [pid 25605] [client 195.144.106.250:62065] mod_fcgid: stderr: #0 /var/www/clients/client1/web6/web/admreg/registrace.php(214): PDOStatement->execute(Array), referer: https://registrace-test.udhpsh.cz/admreg/registrace.php?akce=nova_registrace&formular_id=kk<br/>
                [Wed Jun 08 10:25:34.496035 2022] [fcgid:warn] [pid 25605] [client 195.144.106.250:62065] mod_fcgid: stderr: #1 {"{"}main{"}"}, referer: https://registrace-test.udhpsh.cz/admreg/registrace.php?akce=nova_registrace&formular_id=kk<br/>
                [Wed Jun 08 10:25:34.496045 2022] [fcgid:warn] [pid 25605] [client 195.144.106.250:62065] mod_fcgid: stderr:   thrown in /var/www/clients/client1/web6/web/admreg/registrace.php on line 214, referer: https://registrace-test.udhpsh.cz/admreg/registrace.php?akce=nova_registrace&formular_id=kk<br/>
                </code>
            </div>

        </div>


        <div className="row">
            <div className="col">
                <Link to="/logy-3" className="d-flex justify-content-center">
                Další strana
                </Link>
            </div>
        </div>
    </div>

)

export default Logy2