import React from "react";
import { Link } from "react-router-dom";

const LogyHomePage = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <h1 align="center">JSON</h1>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>
                Log je v informatice obecné označení pro záznam nějaké činnosti nebo pro soubor se záznamy (často s příponou .log), 
                které některé programy vytvářejí pro záznam informací o své činnosti a běhu. 
                Logy slouží při zpětné analýze k rozpoznání, zda došlo k nějaké chybě, a pakliže ano, pak pomáhají určit, 
                k jaké chybě došlo a proč. Mohou také obsahovat informace o tom, jak a kým byla daná aplikace či služba využívána 
                (například z jaké IP adresy bylo přistupováno k webovému serveru). 
                </p>
            </div>

        </div>


        <div className="row">
            <div className="col">
                <Link to="/logy-2" className="d-flex justify-content-center">
                Další strana
                </Link>
            </div>
        </div>
    </div>

)

export default LogyHomePage