import React from 'react';
import {Link} from "react-router-dom";

const TeoriaSQLHomePage = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1>SQL</h1>
                </div>
                <div className="d-flex">
                    <p>SQL je zkratka (anglicky Structured Query Language) pro standardizovaný strukturovaný
                        dotazovací jazyk, který je používán pro práci s daty v relačních databázích.
                        Testerům slouží pro nahlížení do databáze, případně k přípravě testovacích dat.
                    </p>
                </div>
            </div>
        </div>
        <div className="row pt-4 sqlTeorieList">
            <div className="col">
                <h1 className="d-flex justify-content-center">Co dokáže SQL?</h1>
                <ul>
                    <li>SQL může provádět dotazy na databázi</li>
                    <li>SQL dokáže načíst data z databáze</li>
                    <li>SQL umí vkládat záznamy do databáze</li>
                    <li>SQL může aktualizovat záznamy v databázi</li>
                    <li>SQL může mazat záznamy z databáze</li>
                    <li>SQL může vytvářet nové databáze</li>
                    <li>SQL může vytvářet nové tabulky v databázi</li>
                    <li>SQL může vytvářet uložené procedury v databázi</li>
                    <li>SQL může vytvářet pohledy v databázi</li>
                    <li>SQL může nastavit oprávnění pro tabulky, procedury a pohledy</li>
                </ul>
            </div>
        </div>
        <div className="row pt-4 sqlTeorieList">
            <div className="col">
                <h1 className="d-flex justify-content-center">Některé z nejdůležitějších příkazů SQL</h1>
                <ul>
                    <li><span className="sqlTeorieSpan">SELECT</span> - extrahuje data z databáze</li>
                    <li><span className="sqlTeorieSpan">UPDATE</span> - aktualizuje data v databázi</li>
                    <li><span className="sqlTeorieSpan">DELETE</span> - vymaže data z databáze</li>
                    <li><span className="sqlTeorieSpan">INSERT INTO</span> - vloží nová data do databáze</li>
                    <li><span className="sqlTeorieSpan">CREATE DATABASE</span> - vytvoří novou databázi</li>
                    <li><span className="sqlTeorieSpan">ALTER DATABASE</span> - upravuje databázi</li>
                    <li><span className="sqlTeorieSpan">CREATE TABLE</span> - vytvoří novou tabulku</li>
                    <li><span className="sqlTeorieSpan">ALTER TABLE</span> - upravuje tabulku</li>
                    <li><span className="sqlTeorieSpan">DROP TABLE</span> - smaže tabulku</li>
                    <li><span className="sqlTeorieSpan">CREATE INDEX</span> - vytvoří index (klíč vyhledávání)</li>
                    <li><span className="sqlTeorieSpan">DROP INDEX</span> - smaže index</li>
                </ul>
            </div>
        </div>
        <div className="row pt-4">
            <div className="col">
                <Link to="/zakladni_prikazy_sql" className="d-flex justify-content-center">Další strana</Link>
            </div>
        </div>
    </div>
)

export default TeoriaSQLHomePage;