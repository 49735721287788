import React from 'react'
import {Link} from 'react-router-dom';
import {BasicTextArea, BasicButton} from "../../components/reusedComponents";

const SQLExerciseAtoC = () => (
    <div className="container pt-page">
        <div className="row mb-4">
            <div className="col">
                <h1 align="center">SQL Cvičení</h1>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col">
                <p>G) Vypiš celkový počet MD dovolené pro jednotlivé osoby, které čerpaly dovolenou a jsou zaměstnáni na
                    HPP. Typ záznamu v tabulce dovolená řešit nemusíš</p>
                <form id={"questionG"} action="">
                    <BasicTextArea name="answer" id="1" form={"questionG"} cols="1" rows="1" className={"w-100"} required={true}/>
                    <BasicButton type={"submit"} id={"id"} value={"buttonID"} name={"sendButton"} className={"className"}/>
                </form>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col">
                <p>H) Zjisti jakých stavů může nabývat vybavení (vypiš unikátní stavy)</p>
                <form id={"questionH"} action="">
                    <BasicTextArea name="answer" id="1" form={"questionH"} cols="1" rows="1" className={"w-100"} required={true}/>
                    <BasicButton type={"submit"} id={"id"} value={"buttonID"} name={"sendButton"} className={"className"}/>
                </form>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col">
                <p>I) Vypiš počet dokumentů k jednotlivým autorům (v tabulce SPIS)</p>
                <form id={"questionI"} action="">
                    <BasicTextArea name="answer" id="1" form={"questionI"} cols="1" rows="1" className={"w-100"} required={true}/>
                    <BasicButton type={"submit"} id={"id"} value={"buttonID"} name={"sendButton"} className={"className"}/>
                </form>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <Link to="/" className="d-flex justify-content-center">
                    Domů
                </Link>
            </div>
        </div>
    </div>
)

export default SQLExerciseAtoC;