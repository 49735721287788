import React from "react";
import { Link } from "react-router-dom";
import ReactJson from 'react-json-view'
let jsonSimple = { id: 5, name: "Zdistav", surname: "Rozbořil", birth: "1960-5-8" };
let jsonInt = { id_customer: 5, orders: [{ id: 1, name: "Vodka", quantity: 10 }, { id: 2, name: "Rum", quantity: 2 }, { id: 3, name: "Gin", quantity: 5 }] };
let jsonAdv = {
    widget: {
        debug: "on",
        window: {
            title: "Sample Konfabulator Widget",
            name: "main_window",
            width: 500,
            height: 500
        },
        image: {
            src: "Images/Sun.png",
            name: "sun1",
            hOffset: 250,
            vOffset: 250,
            alignment: "center"
        },
        text: {
            data: "Click Here",
            size: 36,
            style: "bold",
            name: "text1",
            hOffset: 250,
            vOffset: 100,
            alignment: "center",
            onMouseUp: "sun1.opacity = (sun1.opacity / 100) * 90;"
        }
    }
}
const JSON2 = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">JSON příklady</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h4>Jednoduchý JSON</h4>
                <ReactJson src={jsonSimple} name={false} theme="bright:inverted" displayObjectSize={false} displayDataTypes={false} enableClipboard={false} />
            </div>

        </div>
        <div className="row">
            <div className="col">
            <h4>JSON s polem objektů</h4>

                <ReactJson src={jsonInt} name={false} theme="bright:inverted" displayObjectSize={false} displayDataTypes={false} enableClipboard={false} />
            </div>
            <div className="col">
            <h4>JSON s vnořenýmy objekty</h4>

                <ReactJson src={jsonAdv} name={false} theme="bright:inverted" displayObjectSize={false} displayDataTypes={false} enableClipboard={false} />
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/json-2" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default JSON2;