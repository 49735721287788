import React from "react";
import {Link} from "react-router-dom";

const XMLHomePage = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">XML syntax</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>Dokumenty XML musí mít kořenový prvek.</p>
                <p>Dokumenty XML musí obsahovat jeden kořenový prvek, který je rodičem všech ostatních prvků:</p>
                <code>
                    {"<root>\n" +
                        "<child>\n" +
                        "<subchild>.....</subchild>\n" +
                        "</child>\n" +
                        "</root>"}
                </code>
                <p>V tomto příkladu je <code>{"<note>"}</code> kořenovým prvkem:</p>
                <code>
                    {"<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
                        "<note>\n" +
                        "<to>Tove</to>\n" +
                        "<from>Jani</from>\n" +
                        "<heading>Reminder</heading>\n" +
                        "<body>Don't forget me this weekend!</body>\n" +
                        "</note>\n"}
                </code>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>XML tagy rozlišují malá a velká písmena</h3>
                <p> XML tagy rozlišují velká a malá písmena. Značka <code>{"<Letter>"}</code> se liší od
                    značky <code>{"<letter>"}</code>.</p>
                <p>
                    Otevírací a zavírací tagy musí být napsány se stejnými velkými a malými písmeny:</p>
                <p><code>{"<message>To je správně</message>"}</code>
                    "Otevírací a uzavírací značky" jsou často označovány jako "počáteční a koncové značky". Použijte, co
                    chcete. Je to přesně to samé.</p>
            </div>
        </div>

        <div className="ro">
            <div className="col">
                <h3>Prvky XML musí být správně vnořeny</h3>
                <p>
                    V HTML můžete vidět nesprávně vnořené prvky:</p>
                <p>

                    <code>{"<b><i>Tento text je zobrazen tučně a kurzívou</b></i>"}</code>
                    V XML musí být všechny prvky správně vnořeny do sebe:

                    <code>{"<b><i>Tento text je zobrazen tučně a kurzívou</i></b>"}</code>
                    Ve výše uvedeném příkladu „Správně vnořeno“ jednoduše znamená, že jelikož je
                    prvek <code>{"<i>"}</code> otevřen uvnitř
                    prvku <code>{"<b>"}</code>, musí být uvnitř prvku <code>{"<b>"}</code> uzavřen.</p>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/xml_syntax" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>)

export default XMLHomePage;