import React from "react";
import {Link} from "react-router-dom";

const TestingLevels = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">Úrovně a základní typy testů</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>Cíle testování</h3>
                <ul>
                    <li>Cílem testování je ověření, zda se aplikace chová dle specifikace. Pokud tomu tak není, je
                        třeba upozornit na odlišné chování. Ve většině případů se upraví chování aplikace a následně
                        je povinností testera tuto opravu zkontrolovat.
                    </li>
                    <li>Dalším cílem je zajistit stabilitu systému a jeho částí</li>
                    <li>Primárním cílem testování NENÍ hledat chyby - hledání chyb je pouze součást procesu,
                        primárně však jde o to, aby aplikace fungovala dle definované specifikace
                    </li>
                </ul>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>Úrovně a základní typy testů</h3>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <ul>
                    <li>Jednotkové testování (UNIT testy)</li>
                    <li>Integrační testování</li>
                    <li>Systémové testování</li>
                    <li>Akceptační testování (UAT)</li>
                </ul>
            </div>
            <div className="col">
                <ul>
                    <li>Regresní a konfirmační testy</li>
                    <li>Smoke a sanity testy</li>
                    <li>Factory testy (FAT)</li>
                </ul>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>Jednotkové testování (UNIT testy)</h3>
                <ul>
                    <li>První úroveň testování</li>
                    <li>Izolované testování jednotlivých stavebních “bloků” (modulů) programu (metoda, třída,
                        procedura)
                    </li>
                </ul>
            </div>

            <div className="col">
                <h3>Integrační testování</h3>
                <ul>
                    <li>Testování vzájemné integrace 2 a více modulů (případně celých aplikací), které spolu souvisí
                    </li>
                    <li>Příklad: Jedna aplikace používá určité funkcionality a prvky z aplikace druhé</li>
                    <li>Cílem je objevit chyby vzniklé spojením a následnou integrací více modulů či systémů a jejich
                        rozhraní
                    </li>
                </ul>

                <ul>
                    <li>
                        Základní rozdělení:
                        <ul>
                            <li>Intersystémové
                                <ul>
                                    <li>Integrace 2 a více systémů do jednoho celku</li>
                                </ul>
                            </li>
                            <li>Intrasystémové
                                <ul>
                                    <li>Integrace modulů do funkčního systému</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li>Nevýhodou integračních testů je, že nelze jednoduše zjistit konkrétní příčinu, proč testovaný
                        celek nefunguje
                    </li>
                    <li>V praxi často spojeno se Systémovým testování v rámci tzv. SIT (Systém and Integration
                        tests)
                    </li>
                </ul>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/testing_levels_2" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default TestingLevels;