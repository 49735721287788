import React from "react";
import {Link} from "react-router-dom";

const TestingHomePage = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">Úvod do testování</h1>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <h3>Co je a co není testování softwaru</h3>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <ul>
                    <li>Řízené spouštění SW produktu s cílem zjistit, zda splňuje specifikované potřeby uživatelů
                        (odpovídá specifikaci, ale i obecným očekáváním o chování SW – např., že křížek zavře
                        okno apod.)
                    </li>
                    <li>Nejedná se tedy o pouhé hledání defektů a chyb v aplikaci</li>
                    <li>Testovánízkoumá SW produkt ⇒ získává informace o jeho kvalitě a stavu</li>
                </ul>
            </div>
            <div className="col">
                <ul>
                    <li>Je prostředkem ke zlepšování kvality SW, ale samo o sobě ji nezaručuje</li>
                    <li>Testování NENÍ pouhé procházení a plnění testovacích scénářů</li>
                </ul>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>Obecný průběh takového “cyklu”:</h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <ul>
                    <li>Požadavky → Analýza → Návrh → Implementace → TESTOVÁNÍ → Akceptace</li>
                    <li>V první fázi sesbíráme požadavky na aplikaci od zadavatele</li>
                    <li>Ve druhé fázi požadavky analyzujeme</li>
                    <li>Na základě analýzy vypracujeme konkrétní návrh aplikace</li>
                </ul>
            </div>
            <div className="col">
                <ul>
                    <li>Jakmile je návrh hotov, pokračujeme fází implementace</li>
                    <li>Po implementaci, případně i během ní, otestujeme naši aplikaci</li>
                    <li>Pokud je zákazník spokojený s aplikací, převezme ji a cyklus je u „konce“ (většinou se
                        pokračuje dalšími požadavky a vše začíná opět od začátku)
                    </li>
                </ul>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>Existují dvě hlavní metodiky týkající se životního cyklu aplikace</h3>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h5>Vodopádový přístup</h5>
                <ul>
                    <li>Metody založené na sekvenčním vývoji aplikace</li>
                    <li>Každá fáze vývoje může začít pouze tehdy, když je ukončená fáze předchozí</li>
                    <li>Výhodou je celková jednoduchost tohoto přístupu</li>
                    <li>Nevýhodou je důraz kladený na dokonalé zpracování požadavků a jejich
                        analýzu. Zákazník musí mít naprosto jasné a ucelené požadavky na aplikaci,
                        které se v ideálním případě nebudou dále měnit ⇒ nevhodné pro rychlé
                        vytváření různých prototypů
                    </li>
                </ul>
            </div>
            <div className="col">
                <h5>Agilní přístup</h5>
                <ul>
                    <li>Metody založené na iterativním a přírůstkovém vývoji aplikace</li>
                    <li>Výhodou je rychlý vývoj SW, který se přizpůsobuje změnám požadavku v samotném průběhu vývoje ⇒
                        ušetřený čas a peníze
                    </li>
                    <li>Při nedostatečném analyzování požadavků je nevýhodou nepřesný odhad
                        časové náročnosti úkolu ⇒ možné prodlevy dodávky SW
                    </li>
                    <li>Nevýhodou je i fakt, že se klade menší důraz na tvorbu dokumentace SW
                        z důvodu rychlých publikování nových verzí SW ⇒ složitější údržba, složitější
                        příprava testování
                    </li>
                </ul>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/testing_levels" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default TestingHomePage;