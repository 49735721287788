import React from "react";
import {Link} from "react-router-dom";

//Images
import values_start from "./img/values_start.png";
import response_xml from "./img/response_xml.png";

const SoapUICreateProject4 = () => (
    <div className="container pt-page soapUI">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">Vytvoření nového SOAP projektu</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>jakmile jsou hodnoty vyplněny, klikni na zelený ‚Play‘</p>
                <img src={values_start} alt={"play"}/>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <br/><br/><br/>
                <img src={response_xml} alt=""/>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/SoapUI_dictionary" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default SoapUICreateProject4;