import React from 'react'
import {Link} from 'react-router-dom';
import {BasicTextArea, BasicButton} from "../../components/reusedComponents";

const SQLExerciseDtoF = () => (
    <div className="container pt-page">
        <div className="row mb-4">
            <div className="col">
                <h1 align="center">SQL Cvičení</h1>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col">
                <p>D) Vypiš seznam vybavení (názvy), které má zapůjčené osoba s ID = 35</p>
                <form id={"questionD"} action="">
                    <BasicTextArea name="answer" id="1" form={"questionD"} cols="1" rows="1" className={"w-100"} required={true}/>
                    <BasicButton type={"submit"} id={"id"} value={"buttonID"} name={"sendButton"} className={"className"}/>
                </form>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col">
                <p>E) Vypiš seznam (názvy a cenu) vybavení SKLADEM seřazený podle ceny vzestupně</p>
                <form id={"questionE"} action="">
                    <BasicTextArea name="answer" id="1" form={"questionE"} cols="1" rows="1" className={"w-100"} required={true}/>
                    <BasicButton type={"submit"} id={"id"} value={"buttonID"} name={"sendButton"} className={"className"}/>
                </form>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>F) Uprav záznam v tabulce vybaveni - pro záznam ID 33 nastav stav na Sklad</p>
                <form id={"questionF"} action="">
                    <BasicTextArea name="answer" id="1" form={"questionF"} cols="1" rows="1" className={"w-100"} required={true}/>
                    <BasicButton type={"submit"} id={"id"} value={"buttonID"} name={"sendButton"} className={"className"}/>
                </form>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <Link to="/SQLExcerciseGtoI" className="d-flex justify-content-center">
                    Další strana
                </Link>
            </div>
        </div>
    </div>
)

export default SQLExerciseDtoF;