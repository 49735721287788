import React from "react";
import { Link } from "react-router-dom";

const RestHttpMetody = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">REST API - HTTP REQUEST METODY</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>Idempotentní</h3>
                <p>Požadavek může být odeslán jednou či vícekrát v řadě se stejným efektem a ponechá server ve stejném stavu</p>
                <h3>Bezpečná (safe)</h3>
                <p>Metoda nemění stav serveru. Jinými slovy, metoda je bezpečná, pokud provádí pouze read-only operace</p>
                <h3>Chacheable</h3>
                <p>Odpověď requestu může být uložena na serveru. Ne všechny metody mohou být uloženy</p>


            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/rest/http-metody-2" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default RestHttpMetody;