import React from "react";
import {Link} from "react-router-dom";
import UML_Generalization from "./img/UML_Generalization.png";

const UmlVztahy2 = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">Teorie UML - Vztahy</h1>
                </div>
            </div>
        </div>
        <div className="row pt-3">
            <div className="col">
                <h3>Generalizace (Generalization)</h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>Posledním vztahem je generalizace. Z hlediska implementace se jedná o dědičnost.
                    Jedna entita dědí vlastnosti a chování jiné. Generalizaci zakreslujeme jako plnou čáru,
                    zakončenou najedné straně prázdnou uzavřenou šipkou(respektive trojúhelníkem). Šipka je na straně
                    entity,
                    ze které se dědí.</p>
                <div className="d-flex justify-content-center">
                    <img src={UML_Generalization} alt="" className="w-30"/>
                </div>
            </div>
        </div>
        <div className="row pt-3">
            <div className="col">
                <h3>Multiplicita (Multiplication)</h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>Multiplicitu můžeme uvést u vazeb asociace, agregace a kompozice(zde pouze z jedné strany).V příkladu
                    sekce-článek:</p>
                <p>Multiplicita se zde čte takto: Sekce může mít libovolný počet článků(to poznáme podle hvězdičky u
                    třídy Článek).
                    Článek patří do 1 až libovolně sekcí(to se pozná podle 1..* u Sekce). Přehled
                    možných zápisú multiplicity:</p>
                <ul>
                    <li>1 (číslo) - Označuje konkrétní hodnotu (zde právě 1).</li>
                    <li>* (hvězdička) - Označuje libovolný počet( tedy i 0). Místo hvězdičky se může v některých
                        materiálech nalézt symbol N.
                    </li>
                    <li>1..* (interval) - Pomocí 2 teček se může označit interval. Do něj se vkládájí již známés ymboly, např.:
                        2..6 nebo 1..* nebo 0..1.
                    </li>
                </ul>
                <p>Pokud není multiplicita uvedena, označuje to výchozí hodnotu 1.</p>
            </div>
        </div>
        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/" className="d-flex justify-content-center">Domů</Link>
                </div>
            </div>
        </div>
    </div>
)

export default UmlVztahy2;