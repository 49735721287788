import React from "react";
import {Link} from "react-router-dom";

const Home = () => (
    <div className="row">
        <div className="col">
            <div className="center-window">
                <div className="col">
                    <div className="flex-container justify-content-center">
                        <Link to="/theory" className="card">
                            <div className="card-body align-items-center d-flex justify-content-center">
                                <p>Teorie</p>
                            </div>
                        </Link>
                        <Link to="/excercise" className="card">
                            <div className="card-body align-items-center d-flex justify-content-center">
                                <p>Cvičení</p>
                            </div>
                        </Link>
                        <Link to="/otazky_na_pohovor" className="card">
                            <div className="card-body align-items-center d-flex justify-content-center">
                                <p>Otázky na pohovor</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Home;
