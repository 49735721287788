import React from "react";
import {Link} from "react-router-dom";

//Images
import wsdl_file from "./img/wsdl_subor.png";
import wsdl_load from "./img/nacitanie_stiahnuteho_suboru.png";
import novy_projekt from "./img/novy_projekt.png";
import request from "./img/request.png";


const SoapUICreateProject2 = () => (
    <div className="container pt-page soapUI">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">Vytvoření nového SOAP projektu</h1>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>WSDL soubor je nutné stáhnout, pro tento projekt, ze stránky
                    (https://wwwinfo.mfcr.cz/ares/xml_doc/schemas/index.html)</p>
                <img src={wsdl_file} alt=""/>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>načtení staženého souboru (checkboxy nechej defaultně)</p>
                <img src={wsdl_load} alt=""/>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>načtení definic a finální vytvoření projektu</p>
                <img src={novy_projekt} alt="novy_projekt"/>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>rozklikni (+) a dostaň se až na ‚Request‘, který též rozklikni</p>
                <p>otevře se obrazovka s danou službou, kde lze doplnit různé hodnoty (formát XML)</p>
                <img src={request} alt="request"/>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/SoapUI_create_project_3" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default SoapUICreateProject2;