import React from "react";
import { Link } from "react-router-dom";

const RestHttpMetody2 = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">REST API - HTTP METODY</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>GET</h3>
                <p>safe,idempotent,cacheable</p>
                <p>GET metodou by se pouze měli získávat data ze serveru</p>
                <h3>POST</h3>
                <p>Cacheable (pouze pokud je společně přenášen header o čerstvosti)</p>
                <p>POST metodou se posílají data na server. Typ dat posílaných v těle (body) požadavku je specifikován hlavičkou (header) <code>Content-type</code></p>
                <p>Tato metoda je typicky posílaná přes HTML formulář a zapříčiní změnu na serveru. Například přídání uživatele, registrace, poslání objednávky atd.</p>
                <h3>PUT</h3>
                <p>Idempotent</p>
                <p>PUT metoda vytvoří novou položku nebo změní stávajicí.</p>
                <p>Rozdíl mezi PUT a POST je takový, že PUT je idempotentní - opakované volání má stejný efekt. Kdežto opakované volání POST by mohlo zapříčinit například vytváření duplicitních objednávek</p>
                <h3>DELETE</h3>
                <p>Idempotent</p>
                <p>Smaže určenou věc - například objednávku</p>

            </div>
            <div className="col">
                <h3>HEAD</h3>
                <p>safe, idempotent, cacheable</p>
                <p>HEAD metoda je stejná jako GET, ale odpověď serveru nemá tělo. To znamená, že se dotazuje pouze na hlavičky.</p>
                <p>Toto je užitečné například při stahování souburů. Klient se nejdříve dotáže na velikost souboru bez nutnosti soubor stahovat.</p>
                <h3>OPTIONS</h3>
                <p>safe, idempotent</p>
                <p>OPTIONS metoda se dotáže na povolené metody požadavků na konretní URL. Odpověd obsahuje <code>Allow</code> header a výčet povolených metod</p>
                <p><code>Allow: OPTIONS, GET, HEAD, POST</code></p>
                <h3>CONNECT</h3>
                <p>Využívána k vytvoření tunelu</p>
                <h3>TRACE</h3>
                <p>safe, idempotent</p>
                <p>Metoda využívána na debugging</p>
                <h3>PATCH</h3>
                <p>adasd</p>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/rest/http-metody-2" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default RestHttpMetody2;