import React from "react";
import {Link} from "react-router-dom";
import img from "./img/http_request.png";

const RestApiHomePage = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">REST API</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h2>HTTP</h2>
                <p>
                    HyperText Transfer Protocol je obecným aplikačním protokolem. Jeho použití není vázáno jen na přenos dokumentů,
                    ale lze jej použít také pro přenos libovolných binárních dat a pro obecnou komunikaci mezi klienty a aplikačními bránami (proxy servery).
                    První verze protokolu vznikla při vývoji systému WWW ve středisku CERN.
                </p>

                <p>
                    HTTP se také říká bezstavový protokol, protože funguje na principu <i>dotaz → odpověď</i>.
                    Například kliknete na nějaký odkaz a váš prohlížeč zašle pomocí HTTP protokolu požadavek s příslušnou adresou na odpovídající server.
                    Zároveň s tím pošle i tzv. HTTP hlavičku (header). Server následně, opět s pomocí HTTP protokolu, pošle zpět HTML soubor (body), společně se stavovým kódem a odlišnou HTTP hlavičkou odpovědi.
                </p>
                <p>
                    Jednotlivé dotazy prohlížeče nejsou z pohledu serveru rozeznatelné. To nevadilo v době jednoduchých statických prezentací,
                    při programování složitějších webových aplikací to však činí problémy, neboť HTTP např. neumožňuje uložení obsahu košíku v internetovém obchodu.
                    To je pak nutné obcházet různými metodami, např. využitím cookies.
                </p>
                <p>
                    K HTTP existuje také jeho zabezpečená varianta HTTPS, zabezpečena protokolem SSL nebo TLS. 
                    Nástupcem HTTP je pak protokol HTTP/2, jež přináší další výhody, například rychlejší načítání stránek.
                </p>
            </div>
        </div>
        <div className="row">
            <div className="col">
                
                <div className="d-flex justify-content-center">
                    <img src={img} title="http request" alt="rest"/>
                </div>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/rest/http-header" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default RestApiHomePage;