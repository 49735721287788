import React from "react";
import {Link} from "react-router-dom";

//Images
import infrastrukturaSoap from "./img/infrastrukturaSoap.png";
import architektura_webovych_sluzeb from "./img/architektura_webovych_sluzieb.png";

const SoapUIWhatIsIt = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">Co je SOAP</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>SOAP je protokol pro posílání zpráv XML a je základem webových služeb. SOAP umožňuje zaslání XML
                    zprávy mezi dvěma aplikacemi. </p>
                <p>Celá infrastruktura webových služeb je založena na třech základních technologiích (vzájemné vztahy
                    jsou zachyceny na obrázku):</p>
                <ul>
                    <li>SOAP (Simple Object Access Protocol) – protokol používaný pro komunikaci</li>
                    <li>WSDL (Web Services Description Language) – standardní formát pro popis rozhraní webové služby
                    </li>
                    <li>UDDI (Universal Description, Discovery and Integration) – standardní mechanismus umožňující
                        registraci a vyhledávání webových služeb
                    </li>
                </ul>
                <div className="row">
                    <div className="col text-center">
                        <img src={infrastrukturaSoap} alt={"infrastruktura"}/>
                        <img src={architektura_webovych_sluzeb} alt="architektura_webovych_sluzeb"/>
                    </div>
                </div>
                <p className={"pt-3"}>Nejčastěji se SOAP používá jako náhrada vzdáleného volání procedur (RPC), tedy v modelu
                    požadavek/odpověď. Jedna aplikace pošle v XML zprávě požadavek druhé aplikaci, tak požadavek
                    obslouží a výsledek zašle jako druhou zprávu zpět původnímu iniciátorovi komunikace. V tomto případě
                    bývá webová služba vyvolána webovým serverem, který čeká na požadavky klientů a v okamžiku, kdy přes
                    HTTP přijde soapová zpráva, spustí webovou službu a předá jí požadavek. Výsledek služby je pak
                    předán zpět klientovi jako odpověď.</p>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/SoapUI_create_project" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default SoapUIWhatIsIt;