import React from "react";
import {Link} from "react-router-dom";

const ZakladnePrikazySQLs2 = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <h1 className="d-flex justify-content-center">Základní SQL příkazy</h1>
            </div>
        </div>
        <div className="row pt-4">
            <div className="col">
                <h3>Částečná shoda ve WHERE podmínce</h3>
            </div>
            <div className="col">
                <h3>Vícenásobné podmínky</h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>Používá se například, když chceme vyhledat určité slovo nebo jeho část v delším textovém řetězci.</p>
                <p>Používáme zástupné znaky (tzv wildcard masky), tím je např. % které nahrazuje libovolný počet znaků
                    nebo _ které nahrazuje jeden libovolný znak.</p>
                <br/>
                <p>Příklad 1: SELECT * FROM uzivatel WHERE jmeno like ‘Ja%’; - dotaz bude vracet všechny záznamy, kde
                    jméno začíná Ja. Například Jan, Jana, Jakub....</p>
                <p>Příklad 2: SELECT * from uzivatel WHERE jmeno like ‘%av%’; - dotaz bude vracet všechny záznamy, kde
                    jméno obsahuje av. Napřílad Pavel, …</p>
                <p>Příklad 3: SELECT * FROM uzivatel WHERE jmeno like ‘Ja_’; - dotaz bude vracet všechny záznamy, kde
                    jméno začíná Ja a následuje jeden znak. Například Jan...</p>
            </div>
            <div className="col">
                <p>Pokud chceme v SQL dotazu uvést více podmínek současně spojíme je pomocí AND.</p>
                <p>Příklad: SELECT * FROM uzivatel WHERE jmeno = ‘Petr’ AND prijmeni = ‘Novotný’ AND…</p>
                <br/>
                <p>Pokud chceme v SQL dotazu uvést několik podmínek kde alespoň jedna musí být splněna (sjednocení)
                    spojíme je pomocí OR.</p>
                <p>Příklad: SELECT * FROM uzivatel WHERE jmeno = “Petr” OR jmeno = “Kuba” OR…</p>
                <br/>
                <p>Počet podmínek v dotazu není omezen.</p>
            </div>
        </div>
        <div className="row pt-4">

        </div>

        <div className="row">

        </div>

        <div className="row pt-4 text-center">
            <div className="col">
                <h3>AGREGAČNÍ FUNKCE</h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>Agregační funkce se hodí v případě, že si chceme něco v rámci SQL dotazu spočítat.</p>
                <table>
                    <thead>
                    <tr>
                        <th>Funkce</th>
                        <th>Použití</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>AVG</td>
                        <td>průměr</td>
                    </tr>
                    <tr>
                        <td>MAX, MIN</td>
                        <td>maximální, minimální hodnota</td>
                    </tr>
                    <tr>
                        <td>SUM</td>
                        <td>součet, suma</td>
                    </tr>
                    <tr>
                        <td>COUNT</td>
                        <td>počet řádků v dotazu</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="row pt-2">
            <div className="col">
                <p>Příklad: SELECT COUNT(ID) FROM uzivatel;</p>
                <p>Vrací počet uživatelů v tabulce uživatel.</p>
                <p>Příklad 2: SELECT SUM(mzda) FROM uzivatel;</p>
                <p>Vrací součet mezd všech záznamů tabulky uživatel.</p>
                <p>Parametr funkce uvádíme do závorky. V případě COUNT by to měl být primární klíč, případně jiná unikátní hodnota. U funkce COUNT je možné do závorky uvést i *.</p>
            </div>
        </div>
        <div className="row pt-4">
            <div className="col">
                <Link to="/zakladni_prikazy_sql_s3" className="d-flex justify-content-center">Další strana</Link>
            </div>
        </div>
    </div>
)
export default ZakladnePrikazySQLs2;