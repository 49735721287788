import React from "react";
import {Link} from "react-router-dom";
import pdf from "./Metodika_testovani.pdf"

const styleDiv = {height: "calc(100% - 48px)"};

document.getElementById('root').style.height = '100vh';

const Testing = () => (
    <div className="container-fluid pt-page h-100">
        <div className="row" style={styleDiv}>
            <div className="col">
                <iframe src={pdf} title="Metodika testovani" frameborder="0" width="100%" height="100%"></iframe>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/" className="d-flex justify-content-center">Domů</Link>
                </div>
            </div>
        </div>
    </div>
)

export default Testing;