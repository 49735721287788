import React from "react";
import {Link} from "react-router-dom";

//Images

import novy_projekt from "./img/vytvorenie_noveho_projektu.png";
import novy_projekt_info from "./img/vytvorenie_noveho_projektu_dopl_info.png";

const SoapUICreateProject = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">Vytvoření nového SOAP projektu</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <h3>Založení nového projektu</h3>
                <ul>
                    <li>rozbalení nabídky ‚File‘</li>
                    <li>kliknout na ‚New SOAP Project‘</li>
                </ul>

                <div className="row">
                    <div className="col text-center">
                        <img src={novy_projekt} alt={"novy_projekt"}/>
                    </div>
                </div>
                <p>V okně ‚New SOAP Project‘ je nutné vyplnit pole ‚Project Name‘ a dále přiložit WSDL soubor</p>
                <div className="row">
                    <div className="col text-center">
                        <img src={novy_projekt_info} alt=""/>
                    </div>
                </div>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/SoapUI_create_project_2" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default SoapUICreateProject;