import React from "react";
import {Link} from "react-router-dom";

const zakladnePrikazys4 = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <h1 className="d-flex justify-content-center">
                    Základní SQL příkazy
                </h1>
            </div>
        </div>
        <div className="row pt-4">
            <div className="col">
                <h3>
                    PŘEJMENOVÁNÍ SLOUPCŮ
                </h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>
                    Slouží pro zpřehlednění výstupu, typicky se používá u agregačních funkcí. Klíčovým slovem pro
                    přejmenování sloupců výstupu je AS.
                </p>
                <p>
                    Příklad: <code>SELECT SUM(mzda) as suma_mezd FROM zamestnanec WHERE smlouva = ‘HPP’</code>
                </p>
                <p>
                    Databáze vrátí sumu mezd všech HPP zaměstnanců a sloupec bude pojmenovaný suma_mezd. Bez přejmenování
                    by se jmenoval SUM(mzda).
                </p>

            </div>
        </div>
        <div className="row pt-4">
            <div className="col">
                <h3>
                    PŘEJMENOVÁNÍ TABULEK
                </h3>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>
                    Zkracuje zápis při JOIN tabulek. Podobně jako lze přejmenovat sloupce, jde přejmenovat i celé tabulky
                    v rámci SQL dotazu. Není zde žádné klíčové slovo, přejmenování se uvede pouze za název tabulky (s
                    mezerou).
                </p>
                <p>
                    Příklad:
                    <code>
                        SELECT * FROM uzivatel u join projekt p on u.ID = p.ID_uzivatel;
                    </code>
                </p>
                <p>
                    V uvedeném příkladu jsem si přejmenoval tabulku uživatel na “u” a tabulku projekt na “p”, zápis joinu
                    se o pár znaků zkrátil. Využití zkracování je dobré, když máme delší SQL dotazy nebo dlouhé a
                    složité názvy tabulek (typické v bankách, kde s pro názvy drží až nesmyslně složité konvence např.
                    “CZ_ERSTE_RETAIL_S24_CIS_TRINITY_REPORT_TRANSACTION” apod. šílenosti)
                </p>
            </div>
        </div>
        <div className="row pt-4">
            <div className="col">
                <Link to="/" className="d-flex justify-content-center">
                    Domů
                </Link>
            </div><div className="col">
                <Link to="/sql_cviceni" className="d-flex justify-content-center">
                    Cvičení
                </Link>
            </div>
        </div>
    </div>
)

export default zakladnePrikazys4;