import React from "react";
import {Link} from "react-router-dom";
import domenovyModelIMG from "./img/domenovy_model_img.png";

const UmlDomenovyDiagram = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">Teorie UML - Doménový model</h1>
                </div>
            </div>
        </div>
        <div className="row pb-4">
            <div className="col">
                <p>Doménovýmodel se vytváříspolus Use Case diagramemv počátečnífázivývojesoftwaru. Jednáse o formuclass
                    diagramu, tedydiagramutříd.Základníentitouje třída. Třídy v doménovém modelu jsou však značně z
                    jednodušené, neobsahují metody a mají pouze důležité atributy.</p>
                <p>Je platformově nezávislý (není určen pro konkrétní programovací jazyk) a atributy nemají datové
                    typy. Pro doménový model se uvádí jen zjednodušená notace s názvem třídy a atributy.</p>
                <br/>
                <div className="d-flex justify-content-center">
                    <img src={domenovyModelIMG} alt=""/>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>Třídy jsou mezi sebou propojeny pomocí vztahů. UML nabízí několik druhů vztahů, ty základní jsou:</p>
                <ul className="pt-2">
                    <li>Asociace (Association)</li>
                    <li>Agregace (Aggregation)</li>
                    <li>Kompozice (Composition)</li>
                    <li>Generalizace (Generalization)</li>
                    <li>Multiplicita (Multiplicity)</li>
                </ul>
            </div>
        </div>

        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/uml_domenovy_model_vztahy_1" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default UmlDomenovyDiagram;