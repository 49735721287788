import React from "react";
import {Link} from "react-router-dom";

const RestHttpHeader = () => (
    <div className="container pt-page">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1 className="">REST API - HTTP HEADERS</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <p>
                    Headery umožňují zasílat dodatečné informace spolu s požadavkem.
                </p>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>Headery můžeme třídit dle jejich kontextu:</p>
                <div className="row">
                    <div className="col">
                        <h3>Request Header</h3>
                        <p>
                            Je zasílán při požadavku klienta na server.
                        </p>
                        <code>Accept: application/json</code>
                        <p>
                            Řekne serveru, že rozumí JSONu a potřebuje dostat odpověď právě v tomto formátu
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Response Header</h3>
                        <p>
                            je zaslán při odpovědi serveru na dotaz klienta.
                        </p>
                        <code>Location: example.com/new</code>
                        <p>
                            Location header značí url pro přesměrování. Pouze relevantní, pokud je zaslán kód 3xx nebo
                            201
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Representation Header</h3>
                        <p>
                            Popisuje reprezentaci zdroje zaslaného serverem. Jinými slovy, co vlastně posílá (MIME Type)
                        </p>
                        <code>Content-Type: text/html</code>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Payload Header</h3>
                        <p>
                            Headery, které popisují informace o přenášeném obsahu v související s bezpečným přenosem a
                            rekonstrukcí reprezentace původního zdroje z jedné nebo více zpráv.
                        </p>
                        <code>Content-Length: 800;Transfer-Encoding: gzip</code>
                    </div>
                </div>


                <p>Headerů je velké množství a jsou zde uvedené jen některé příklady. Pro celý výčet klikněte <a
                    href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers" target="_blank" rel="noreferrer">zde</a></p>
            </div>
        </div>


        <div className="row pt-4">
            <div className="row">
                <div className="col">
                    <Link to="/rest/http-header-2" className="d-flex justify-content-center">Další strana</Link>
                </div>
            </div>
        </div>
    </div>
)

export default RestHttpHeader;